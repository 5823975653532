<template>
  <div class="security">
    <!-- <div class="tip">{{ $t('security.tip') }}</div> -->
    <h2 class="g-title title g-container">{{ $t("security.title") }}</h2>
    <div class="title g-container">
      <img
        class="circle-img"
        src="@/assets/accept-left-circle12.png"
        alt=""
      />
    </div>
    <div class="top g-container">
      <div
        :class="['top__item', { top__item_show1: securityShow }]"
        id="security-show-1"
      >
        <img src="@/assets/ibm.png" class="top__item__img" alt="" />
        <h3 class="top__item__title g-font-mid">
          {{ $t("security.s11title") }}
        </h3>
        <div class="top__item__content">{{ $t("security.s11content") }}</div>
      </div>
      <div
        :class="['top__item', { top__item_show2: securityShow }]"
        id="security-show-1"
      >
        <img src="@/assets/security.png" class="top__item__img" alt="" />
        <h3 class="top__item__title g-font-mid">
          {{ $t("security.s12title") }}
        </h3>
        <div class="top__item__content">{{ $t("security.s12content") }}</div>
      </div>
      <div
        :class="['top__item', { top__item_show3: securityShow }]"
        id="security-show-1"
      >
        <img src="@/assets/encryption.png" class="top__item__img" alt="" />
        <h3 class="top__item__title g-font-mid">
          {{ $t("security.s13title") }}
        </h3>
        <div class="top__item__content">{{ $t("security.s13content") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Security',
  data() {
    return {
      securityShow: false,
      idIndex: [1],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      // 视窗高度
      const domHeight = window.screen.height;
      // dom滚动位置
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop;
      // 获取监听元素
      let id;
      // 获取元素本身高度
      let scrollHeight;
      // 获取监听元素距离视窗顶部距离
      let offsetTop;
      // 获取监听元素距离顶部高度-窗口高度
      let top;
      // 元素距离底部的高度+元素本身高度
      let bottom;
      // eslint-disable-next-line array-callback-return
      this.idIndex.map((i) => {
        id = document.getElementById(`security-show-${i}`);
        // eslint-disable-next-line prefer-destructuring
        scrollHeight = id.scrollHeight;
        // eslint-disable-next-line prefer-destructuring
        offsetTop = id.offsetTop;
        top = offsetTop - domHeight > 0 ? offsetTop - domHeight : 0;
        bottom = scrollHeight + offsetTop;
        // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
        if (scrollTop >= top && scrollTop <= bottom) {
          this.securityShow = true;
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
};
</script>

<style lang="scss" scoped>
.security {
  padding-top: 120px;
  text-align: center;
}

.tip {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content);
}

.title {
  text-align: left;
}

.top {
  padding-bottom: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  justify-items: center;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
}

.top__item {
  min-height: 500px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0px 40px 80px 0px rgba(0, 69, 255, 0.1);
  border-radius: 10px;
  background: #fff;

  @media (max-width: 960px) {
    max-width: 400px;
  }
}

.top__item_show1 {
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-name: scrollFadeInUp1;
}

@keyframes scrollFadeInUp1 {
  0% {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.top__item_show2 {
  animation-fill-mode: both;
  animation-duration: 2s;
  animation-name: scrollFadeInUp2;
}

@keyframes scrollFadeInUp2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.top__item_show3 {
  animation-fill-mode: both;
  animation-duration: 3s;
  animation-name: scrollFadeInUp3;
}

@keyframes scrollFadeInUp3 {
  0% {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.top__item__img {
  margin-top: 80px;
  height: 80px;
}

.top__item__title {
  margin-top: 30px;
  font-size: 24px;
  line-height: 28px;
}

.top__item__content {
  padding: 0 40px;
  margin-top: 30px;
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content);
}

.info {
  /*margin: 117px auto 0;*/
  max-width: 500px;
  text-align: left;
}

.info__link {
  display: inline-block;
  margin-top: 40px;
  font-size: 18px;
  line-height: 22px;
  color: rgba(94, 207, 197, 1);
  text-decoration: underline;
}

.info__tip {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content);
}

.info__title {
  font-size: 40px;
  line-height: 50px;
}

.info__content {
  margin-top: 30px;
  color: var(--color-content);
  font-size: 18px;
  line-height: 28px;
}

.info--img__wrapper {
  /*background-image: url("@/assets/security-bg.png");*/
  /*background-repeat: no-repeat;*/
  /*background-size: auto 100%;*/
  /*background-position: top right;*/
  /*padding-bottom: 200px;*/
  position: relative;
}

.info--img {
  max-width: 100%;
}

.info--img__bg {
  position: absolute;
  left: 0;
  z-index: 0;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  align-items: center;

  & > *:nth-child(2) {
    justify-self: right;
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-row-gap: 100px;
    & > *:nth-child(2) {
      justify-self: center;
    }

    &.right {
      & > *:nth-child(1) {
        grid-row: 2;
      }
      & > *:nth-child(2) {
        grid-row: 1;
      }
    }
  }
}

.circle-img {
  margin-left: 70%;
}
</style>
