<template>
  <div class="security" id="why-init-container">
    <div class="wrapper right">
      <div class="row right g-container">
        <div class="info">
          <div class="circle-img-container">
            <img
              class="circle-img1"
              src="@/assets/accept-left-circle1.svg"
              alt=""
            />
          </div>
          <div class="circle-img-container circle-img-container2">
            <img
              class="circle-img2"
              src="@/assets/accept-left-circle2.svg"
              alt=""
            />
          </div>
          <div class="circle-img-container">
            <img
              class="circle-img5"
              src="@/assets/accept-left-circle5.svg"
              alt=""
            />
          </div>
          <div class="info__title g-font-mid">
            <span>
              {{ $t("security.s21title") }}
            </span>
          </div>
          <div class="info__content">
            <div>{{ $t("security.s21content1") }}</div>
          </div>
          <div class="circle-img-container">
            <div class="circle-img">
              <img
                class="circle-img3"
                src="@/assets/accept-left-circle3.svg"
                alt=""
              />
            </div>
            <div class="circle-img">
              <img
                class="circle-img4"
                src="@/assets/accept-left-circle4.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="info--img__wrapper info--img__wrapper-right container">
          <div>
            <div class="dot"></div>
            <div class="pulse p1"></div>
            <div class="pulse p2"></div>
            <div class="pulse p3"></div>
          </div>
          <img
            class="info--img-phone"
            src="@/assets/accept-right-phone.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="wrapper right wrapper-phone">
      <div class="right row g-container">
        <div class="info">
          <div class="info__title g-font-mid">
            <span>
              {{ $t("security.s23title") }}
            </span>
          </div>
          <div class="info__content info__content_why">
            <img
              class="info--img"
              src="@/assets/accept-left-circle6.svg"
              alt=""
            />
          </div>
        </div>
        <div class="info--img__wrapper">
          <div class="img-top-container">
            <img
              class="info--img-crypto info--img-crypto1"
              src="@/assets/accept-right-circle1.svg"
              alt=""
            />
          </div>
          <div class="crypto-right-container">
            <div class="crypto-right-item">
              <div class="crypto-img">
                <img src="@/assets/accept-right-circle2.svg" alt="" />
              </div>
              <div class="crypto-right-item-context">
                <h3 class="crypto-title">{{ $t("security.s23title1") }}</h3>
                <span class="crypto-text">{{
                  $t("security.s23content3")
                }}</span>
              </div>
            </div>
            <div class="crypto-right-item">
              <div class="crypto-img">
                <img src="@/assets/accept-right-circle3.svg" alt="" />
              </div>
              <div class="crypto-right-item-context">
                <h3 class="crypto-title">{{ $t("security.s23title2") }}</h3>
                <span class="crypto-text">{{
                  $t("security.s23content4")
                }}</span>
              </div>
            </div>
            <div class="crypto-right-item">
              <div class="crypto-img">
                <img src="@/assets/accept-right-circle4.svg" alt="" />
              </div>
              <div class="crypto-right-item-context">
                <h3 class="crypto-title">{{ $t("security.s23title3") }}</h3>
                <span class="crypto-text">{{
                  $t("security.s23content5")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="img-top-container-phone g-container">
        <img
          class="info--img-8"
          src="@/assets/accept-left-circle6.svg"
          alt=""
        />
        <div class="info--img-phone-container">
          <img
            class="info--img-9"
            src="@/assets/accept-right-circle5.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="wrapper right">
      <div class="row right g-container">
        <div class="info">
          <div class="info__title g-font-mid">
            <span>
              {{ $t("security.s23title4") }}
            </span>
          </div>
          <div class="circle-img-container">
            <img
              class="circle-img6"
              src="@/assets/accept-left-circle7.svg"
              alt=""
            />
          </div>
          <div class="circle-img-container">
            <img
              class="circle-img7"
              src="@/assets/accept-left-circle5.svg"
              alt=""
            />
          </div>
          <div class="crypto-right-container">
            <div
              :class="[
                'why-inst-item',
                { 'why-inst-item-show': whyInstItemShow1 },
              ]"
              id="why-inst-item-1"
            >
              <div class="why-inst-img">
                <img src="@/assets/undraw-around.svg" alt="" />
              </div>
              <div class="why-inst-item-context">
                <h3 class="why-inst-title">{{ $t("security.s23title5") }}</h3>
                <span class="why-inst-text">{{
                  $t("security.s23content6")
                }}</span>
              </div>
            </div>
            <div
              :class="[
                'why-inst-item',
                { 'why-inst-item-show': whyInstItemShow2 },
              ]"
              id="why-inst-item-2"
            >
              <div class="why-inst-img">
                <img src="@/assets/undraw-segmentation.svg" alt="" />
              </div>
              <div class="why-inst-item-context">
                <h3 class="why-inst-title">{{ $t("security.s23title6") }}</h3>
                <span class="why-inst-text">{{
                  $t("security.s23content7")
                }}</span>
              </div>
            </div>
          </div>
          <div class="circle-img-container">
            <img
              class="circle-img8"
              src="@/assets/accept-left-circle9.svg"
              alt=""
            />
          </div>
          <div class="circle-img-container">
            <img
              class="circle-img7"
              src="@/assets/accept-left-circle8.svg"
              alt=""
            />
          </div>
        </div>
        <div class="crypto-right-container">
          <img
            class="info--img-crypto info--img-crypto-phone"
            src="@/assets/accept-right-circle5.svg"
            alt=""
          />
          <div
            :class="[
              'why-inst-item',
              { 'why-inst-item-show': whyInstItemShow3 },
            ]"
            id="why-inst-item-3"
          >
            <div class="why-inst-img">
              <img src="@/assets/undraw-browsing.svg" alt="" />
            </div>
            <div class="why-inst-item-context">
              <h3 class="why-inst-title">{{ $t("security.s23title7") }}</h3>
              <span class="why-inst-text">{{
                $t("security.s23content8")
              }}</span>
            </div>
          </div>
          <div
            :class="[
              'why-inst-item',
              { 'why-inst-item-show': whyInstItemShow4 },
            ]"
            id="why-inst-item-4"
          >
            <div class="why-inst-img">
              <img src="@/assets/undraw-security.svg" alt="" />
            </div>
            <div class="why-inst-item-context">
              <h3 class="why-inst-title">{{ $t("security.s23title8") }}</h3>
              <span class="why-inst-text">{{
                $t("security.s23content9")
              }}</span>
            </div>
          </div>
          <div
            :class="[
              'why-inst-item',
              { 'why-inst-item-show': whyInstItemShow5 },
            ]"
            id="why-inst-item-5"
          >
            <div class="why-inst-img">
              <img src="@/assets/undraw-windows.svg" alt="" />
            </div>
            <div class="why-inst-item-context">
              <h3 class="why-inst-title">{{ $t("security.s23title9") }}</h3>
              <span class="why-inst-text">{{
                $t("security.s23content10")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Security',
  data() {
    return {
      whyInstItemShow1: false,
      whyInstItemShow2: false,
      whyInstItemShow3: false,
      whyInstItemShow4: false,
      whyInstItemShow5: false,
      idIndex: [1, 2, 3, 4, 5],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      // 视窗高度
      const domHeight = window.screen.height;
      // dom滚动位置
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop
        || document.body.scrollTop;
      // 获取监听元素
      let id;
      // 获取元素本身高度
      let scrollHeight;
      // 获取监听元素距离视窗顶部距离
      let offsetTop;
      // 获取监听元素距离顶部高度-窗口高度
      let top;
      // 元素距离底部的高度+元素本身高度
      let bottom;
      // eslint-disable-next-line array-callback-return
      this.idIndex.map((i) => {
        id = document.getElementById(`why-inst-item-${i}`);
        // eslint-disable-next-line prefer-destructuring
        scrollHeight = id.scrollHeight;
        // eslint-disable-next-line prefer-destructuring
        offsetTop = id.offsetTop;
        top = offsetTop - domHeight > 0 ? offsetTop - domHeight : 0;
        bottom = scrollHeight + offsetTop;
        // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
        if (scrollTop >= top && scrollTop <= bottom) {
          this[`whyInstItemShow${i}`] = true;
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
};
</script>

<style lang="scss" scoped>
.security {
  padding-top: 80px;
  text-align: center;
}

.tip {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content);
}

.title {
  margin-top: 10px;
}

.top {
  margin-top: 100px;
  padding-bottom: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  justify-items: center;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
}

.top__item {
  min-height: 500px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: #fff;

  @media (max-width: 960px) {
    max-width: 400px;
  }
}

.top__item__img {
  margin-top: 80px;
  height: 80px;
}

.top__item__title {
  margin-top: 30px;
  font-size: 24px;
  line-height: 28px;
}

.top__item__content {
  padding: 0 40px;
  margin-top: 30px;
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content);
}

.info {
  text-align: left;
}

.circle-img-container {
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  @media (max-width: 960px) {
    margin-bottom: 0;
  }
}

.circle-img-container2 {
  @media (max-width: 960px) {
    justify-content: flex-end;
  }
}

.circle-img1 {
  width: 25%;
  margin-left: 15%;
  max-width: 120px;
  @media (max-width: 960px) {
    margin-left: 0;
  }
}

.circle-img2 {
  width: 25%;
  margin-left: 65%;
  max-width: 120px;
}

.circle-img {
  flex: 1;
  margin-top: 40px;
}

.circle-img3 {
  @media (max-width: 960px) {
    height: 40px;
    width: 40px;
  }
}

.circle-img4 {
  padding-top: 30px;
  @media (max-width: 960px) {
    padding-top: 20px;
    height: 140px;
    width: 140px;
  }
}

.circle-img5 {
  display: inline-block;
  margin: auto;
  @media (max-width: 960px) {
    width: 30px;
    margin-top: -20px;
  }
}

.img-top-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 74px;
  @media (max-width: 960px) {
    margin-bottom: 0;
  }
}
.img-top-container-phone {
  @media (max-width: 960px) {
    margin-bottom: 0;
    margin-top: 25px;
  }
}
.info--img-phone-container {
  display: flex;
  justify-content: flex-end;
}
.info--img-8 {
  display: none;
  @media (max-width: 960px) {
    display: block;
    max-width: 120px;
  }
}
.info--img-9 {
  display: none;
  @media (max-width: 960px) {
    display: block;
    max-width: 95px;
  }
}
.info__link {
  display: inline-block;
  margin-top: 40px;
  font-size: 18px;
  line-height: 22px;
  color: rgba(94, 207, 197, 1);
  text-decoration: underline;
}

.info__tip {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content);
}

.info__title {
  font-size: 60px;
  line-height: 70px;
  font-family: SamsungSharpSans-Bold;
  @media (max-width: 960px) {
    margin-top: 20px;
    font-size: 30px;
    line-height: 35px;
  }
}

.info__content {
  margin-top: 30px;
  color: #546175;
  font-size: 18px;
  line-height: 28px;
}

.info__content_why {
  @media (max-width: 960px) {
    display: none;
  }
}

.info--img__wrapper {
  position: relative;
}

.info--img {
  max-width: 100%;
}

.info--img__bg {
  position: absolute;
  left: 0;
  z-index: 0;
}

.wrapper {
  &.right {
    padding-bottom: 100px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
    @media (max-width: 960px) {
      background: none;
      padding: 0;
    }
    &.wrapper-phone {
      @media (max-width: 960px) {
        padding-bottom: 0;
      }
    }
  }

  &.left {
    padding-bottom: 200px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    @media (max-width: 960px) {
      background: none;
    }
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  align-items: center;

  & > *:nth-child(2) {
    justify-self: right;
  }

  @media (max-width: 960px) {
    display: inline-block;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-row-gap: 100px;
    padding: 0;
    & > *:nth-child(2) {
      justify-self: center;
    }

    &.right {
      & > *:nth-child(1) {
        grid-row: 1;
      }
      & > *:nth-child(2) {
        grid-row: 2;
      }
    }

    .container {
      display: none;
    }
  }
}

.info--img-crypto {
  display: block;
}

.info--img-crypto-phone {
  @media (max-width: 960px) {
    display: none;
  }
}

.info--img-crypto1 {
  height: 100px;
  width: 100px;
  margin-top: -80px;
}

.crypto-title {
  text-align: left;
  font-size: 28px;
  font-weight: 500;
  font-family: Roboto-Medium;
  margin-bottom: 24px;
  @media (max-width: 960px) {
    font-size: 18px;
    text-align: left;
  }
}

.crypto-text {
  color: #546175;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  font-family: Poppins-Regular;
  @media (max-width: 960px) {
    font-family: Poppins-Regular;
    font-size: 12px;
    line-height: 16px;
    margin-top: 15px;
  }
}

.crypto-img {
  display: flex;
  img {
    width: 100px;
  }
}

.crypto-right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crypto-right-item {
  display: flex;
  padding: 20px;
  flex-direction: row;
  border-radius: 16px;
  max-width: 540px;
  cursor: pointer;
  @media (max-width: 960px) {
    padding: 20px 0;
  }
}

.crypto-right-item:hover {
  box-shadow: 0px 20px 60px 0px rgba(0, 69, 255, 0.06);
  transition: all 1s;
}

.crypto-right-item-context {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  @media (max-width: 960px) {
    padding-left: 10px;
  }
}

.circle-img6 {
  width: 25%;
  max-width: 120px;
  margin-left: 60%;
}

.circle-img7 {
  margin-left: 15%;
  @media (max-width: 960px) {
    display: none;
  }
}

.circle-img8 {
  margin-left: 65%;
  @media (max-width: 960px) {
    display: none;
  }
}

.why-inst-item {
  display: flex;
  padding: 80px 30px;
  box-shadow: 0px 40px 80px 0px rgba(0, 69, 255, 0.1);
  border-radius: 16px;
  flex-direction: column;
  max-width: 540px;
  margin-bottom: 40px;
}

.why-inst-item-show {
  animation-fill-mode: both;
  animation-duration: 2s;
  animation-name: scrollFadeInUp;
}

@keyframes scrollFadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.why-inst-item-context {
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.why-inst-title {
  font-size: 28px;
  font-weight: 500;
  font-family: Roboto-Medium;
  margin-bottom: 30px;
}

.why-inst-text {
  font-size: 16px;
  color: var(--color-content);
}

.why-inst-img {
  display: flex;
  margin-bottom: 60px;
}

.container {
  position: relative;
}

.info--img__wrapper-right {
  width: 540px;
  height: 674px;
}

.info--img-phone {
  position: absolute;
  top: 35px;
  left: 70px;
}

.dot {
  position: absolute;
  width: 400px;
  height: 400px;
  left: 70px;
  top: 70px;
  border-radius: 50%;
  background-color: #0045ff;
}

.pulse {
  position: absolute;
  width: 480px;
  height: 480px;
  left: 30px;
  top: 30px;
  border-radius: 50%;
  opacity: 0;
}

.p1 {
  background-color: #99b5ff;
  animation: warn 2s linear infinite;
}

.p2 {
  background-color: #ccdaff;
  animation: warn2 2s linear infinite;
}

.p3 {
  background-color: #e5ecff;
  animation: warn3 2s linear infinite;
}

@keyframes warn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }

  // 25% {
  //   transform: scale(0.9);
  //   opacity: 0.1;
  // }

  50% {
    transform: scale(1);
    opacity: 0.5;
  }

  // 75% {
  //   transform: scale(1.1);
  //   opacity: 0.5;
  // }

  // 100% {
  //   transform: scale(1.2);
  //   opacity: 0;
  // }

  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

@keyframes warn2 {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  // 25% {
  //   transform: scale(1);
  //   opacity: 0.1;
  // }

  50% {
    transform: scale(1.1);
    opacity: 0.4;
  }

  // 75% {
  //   transform: scale(1.2);
  //   opacity: 0.5;
  // }

  // 100% {
  //   transform: scale(1.3);
  //   opacity: 0;
  // }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes warn3 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.3;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
</style>
