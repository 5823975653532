<template>
  <div>
    <div class="footer">
      <div class="box">
        <div class="box-left">
          <div class="g-container container">
            <div class="g-font-mid title">
              <div>{{ $t("footer.title1") }}</div>
              <div>{{ $t("footer.title2") }}</div>
            </div>
            <a
              class="g-link-clear btn btn--contact"
              href="mailto:support@inst.money"
            >
              {{ $t("footer.contact") }}
            </a>
          </div>
          <div class="g-container container container-img" />
        </div>
        <div class="box__terms">
          <div class="g-container">
            <LinkBox class="terms__term" key="b">
              <a
                class="link-a"
                href="https://t.me/+xhvwZc6YLRU1NmJl"
                target="_blank"
                >TELEGRAM</a
              >
            </LinkBox>
            <span class="right">© 2022 Inst. All rights reserved.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footerAffix">
      <div class="footerAffixContainer">
        <a class="footerLink" href="mailto:support@inst.money">
          <img class="dec" src="@/assets/kefu.svg" alt="check" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LinkBox from '@/components/LinkBox.vue';

export default {
  name: 'Footer',
  components: { LinkBox },
};
</script>

<style lang="scss" scoped>
.container {
  @media (max-width: 1280px) {
    max-width: 760px;
    flex: 1;
  }
}

.box-left {
  margin-bottom: 40px;
}

.container-img {
  @media (max-width: 760px) {
    display: none;
  }
}

.title {
  font-size: 40px;
  line-height: 60px;
  @media (max-width: 960px) {
    font-size: 30px;
    line-height: 40px;
  }
}

.btn {
  box-sizing: border-box;
  display: inline-block;
  margin-top: 40px;
  padding: 0 40px;
  height: 60px;
  line-height: 58px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  color: #0045ff;
  font-size: 18px;
  opacity: 0.8;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
}

.btn--contact {
  margin-right: 60px;
  border: 1px solid #0045ff;
  background: #0045ff;
  color: #fff;
}

.btn--login {
  border: 1px solid #0045ff;
  background: transparent;
}

.terms__term {
  color: #000000;
  transition: color 0.3s;
  line-height: 16px;
  /deep/ .link-box__dec {
    background: #000000;
  }

  &:hover {
    color: var(--color-base-light);
    font-weight: bold;
  }

  &.first {
    margin-right: 20px;
  }
}

.right {
  float: right;
  color: var(--color-content);
}

.box__terms {
  border-top: 1px solid #f2f2f2;
  line-height: 80px;
}

.link-a {
  text-decoration: none;
  color: #000000;
}
.footerAffix {
  text-align: end;
  margin-top: -48px;
  .footerAffixContainer {
    position: fixed;
    bottom: 40px;
    height: 48px;
    right: 20px;
    .footerLink {
      cursor: pointer;
      text-decoration: underline;
      width: 20px;
      .dec {
        width: 80px;
      }
    }
  }
}
</style>
