<template>
  <div>
    <NavHeader />
    <NavHeaderMobile />
    <div class="wrapper">
      <Accept />
      <Crypto />
      <Security />
      <Footer />
    </div>
  </div>
</template>

<script>
import Accept from '@/views/OnlinePayment/Accept.vue';
import Crypto from '@/views/OnlinePayment/Crypto.vue';
import Security from '@/views/OnlinePayment/Security.vue';
import Footer from '@/views/OnlinePayment/Footer.vue';
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';

export default {
  name: 'OnlinePayment',
  components: {
    NavHeader,
    Accept,
    Footer,
    Security,
    Crypto,
    NavHeaderMobile,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: var(--background-common);
}
</style>
